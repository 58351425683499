import React from "react";
import { usePageContext } from "@providers/PageProvider";
interface TypeClientProps {
  children: JSX.Element;
}
const View: React.FC<TypeClientProps> = ({ children }) => {
  const { isSSR } = usePageContext();
  if (!!isSSR) return null;
  return children ?? null;
};
export default View;
