import Image from "@components/Image";
import styles from "./OurJourney.module.scss";
import cx from "classnames";
const View = ({ image, title, className }: any) => {
  return (
    <div className={cx("slide-item animated", styles.slideItem, className)}>
      {/* <div className={styles.overlay}></div> */}
      <div className={styles.slideTitle}>{title}</div>

      {/* <div className="absolute z-10 w-full h-full bg-black/30"></div> */}
      <Image
        {...image}
        speed={0}
        scale="1.02"
        wrapperProps={{ className: styles.wrapperImage }}
        className={styles.image}
        layout="fill"
        objectFit="cover"
      />
    </div>
  );
};
export default View;
// Changing the way you think about furniture design.
