import { useTranslationContext } from "@providers/TranslationProvider";
import styles from "./OurJourney.module.scss";
import cx from "classnames";
import Image from "@components/Image";
import Swiper from "@components/Swiper";
import SlideItem from "./SlideItem";
import useSwiper from "@hooks/useSwiper";
import _ from "lodash";
import "swiper/css";
import "swiper/css/effect-fade";
import { usePageContext } from "@providers/PageProvider";
import { homePage } from "@utils/helper";
import renderPropsComposer from "@utils/renderPropsComposer";

const View = () => {
  const { Trans } = useTranslationContext();
  const { pageData } = usePageContext();
  const ourJourney = homePage.formatOurJourney(pageData);
  const isAuto = _.get(ourJourney, "slider.isAuto");
  const interval = _.get(ourJourney, "slider.interval");

  const middleBanner = homePage.formatMiddleBanner(pageData);
  const displayType = _.get(middleBanner, "displayType") ?? "default";
  const youtubeVideoEmbedLink = _.get(middleBanner, "youtubeVideoEmbedLink");
  const image = _.get(middleBanner, "image");

  useSwiper(".swiper-journey", {
    slidesPerView: 5,
    spaceBetween: 16,
    autoplay: !!isAuto && {
      delay: interval,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      1280: {
        slidesPerView: 5,
      },
    },
  });
  return (
    <section data-scroll-section className={cx(styles.section)}>
      <div className="divider"></div>
      <div className={styles.video}>
        {renderPropsComposer(
          {
            matcher: ({ displayType }: any) => displayType === "video",
            render: () => (
              <iframe
                width="100%"
                height="100%"
                src={youtubeVideoEmbedLink}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ),
          },
          {
            matcher: "default",
            render: () => (
              <div className={styles.logo}>
                <Image
                  {...image}
                  speed="0"
                  wrapperProps={{ className: styles.wrapperImage }}
                  className={styles.image}
                  src={_.get(image, "src") || "/amanaki-logo.png"}
                  alt={_.get(image, "alt") || "about amanaki"}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            ),
          }
        )({ displayType })}
      </div>
      <div className={cx(styles.box)}>
        <Image
          speed="0"
          wrapperProps={{ className: styles.wrapperBg }}
          className={styles.imageBg}
          src={"/home/journey/bg-journey.png"}
          alt={"about amanaki"}
          layout="fill"
          objectFit="cover"
        />

        <div className={cx("inner-large", styles.content)}>
          <div className={cx("flex", styles.title)}>
            <div className="flex-1 text-center ama-section-title">
              {_.get(ourJourney, "title")}
            </div>
          </div>
          <div className={cx("flex justify-center", styles.subTitle)}>
            <div className="ama-content text-center max-w-[784px]">
              <Trans
                defaults={_.get(ourJourney, "description")}
                components={[<br key="br" />]}
              />
            </div>
          </div>
          <Swiper className={cx("swiper-journey", styles.swiper)}>
            {_.map(
              _.get(ourJourney, "slider.slides"),
              (item: any, index: string) => {
                return (
                  <SlideItem
                    className="swiper-slide"
                    {...item}
                    title={
                      <div>
                        <div
                          className={cx(
                            "text-c-1 ama-sub-title",
                            styles.journeyTitle
                          )}
                        >
                          {_.get(item, "title")}
                        </div>
                        <div
                          className={cx(
                            "text-c-1 ama-content pt-[1rem]",
                            styles.journeyContent
                          )}
                        >
                          {_.get(item, "description")}
                        </div>
                      </div>
                    }
                    key={`slide_${""}_${index}`}
                  />
                );
              }
            )}
          </Swiper>
          {/* <div className={styles.imageBox}>
            <Image
              speed="-1.5"
              wrapperProps={{ className: styles.wrapperImage }}
              className={styles.image}
              src={"/home/about.jpg"}
              alt={"about amanaki"}
              layout="fill"
              objectFit="contain"
            />
          </div> */}
          {/* <div className={cx("mx-auto", styles.button)}>
            <Button className="block mx-auto" label="Read more" />
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default View;
