import styles from "./OurStory.module.scss";
import { useTranslationContext } from "@providers/TranslationProvider";
import cx from "classnames";
import Button from "@components/Button";
import Link from "@components/Link";
import VerticalItem from "./VerticalItem";
import HorizontalItem from "./HorizontalItem";
import ClientWrapper from "@containers/ClientWrapper";
import _ from "lodash";
import { usePageContext } from "@providers/PageProvider";
import { homePage } from "@utils/helper";

const View = () => {
  const { i18n } = useTranslationContext();
  const { pageData } = usePageContext();
  const ourStories = homePage.formatOurStories(pageData);
  const POSTS = _.get(ourStories, "stories", []);
  // console.log({ ourStories });
  return (
    <ClientWrapper>
      <section data-scroll-section className={cx("", styles.section)}>
        <div className="divider"></div>
        <div className={cx("inner-large", styles.boxTitle)}>
          <div className="flex flex-1">
            <Link
              className="cursor-pointer ama-section-title"
              href={_.get(ourStories, "heading.button.href")}
            >
              {_.get(ourStories, "heading.title")}
            </Link>
          </div>
          <Link
            className="flex items-center"
            href={_.get(ourStories, "heading.button.href")}
          >
            <Button
              className="block mx-auto"
              type="icon"
              // onClick={() => router.push("stories")}
              label={
                <div className={styles.btnLabel}>
                  {_.get(ourStories, "heading.button.label")}
                </div>
              }
              suffix="shape-arrow"
            />
          </Link>
        </div>
        <div className={cx("inner-large", styles.box)}>
          <div className={styles.colL}>
            <VerticalItem
              {...POSTS[0]}
              topic={i18n.t(_.get(POSTS[0], "topic"))}
              className={cx(styles.vertical)}
            />
            <HorizontalItem
              {...POSTS[2]}
              topic={i18n.t(_.get(POSTS[2], "topic"))}
            />
          </div>
          <div className={styles.colR}>
            <HorizontalItem
              {...POSTS[1]}
              topic={i18n.t(_.get(POSTS[1], "topic"))}
            />
            <VerticalItem
              {...POSTS[3]}
              topic={i18n.t(_.get(POSTS[3], "topic"))}
              className={cx(styles.vertical)}
            />
          </div>
        </div>
      </section>
    </ClientWrapper>
  );
};
export default View;
