import React from "react";
import useSwiper from "@hooks/useSwiper";
import SlideItem from "./SlideItem";
import cx from "classnames";
import "swiper/css/pagination";
import _ from "lodash";
import { Autoplay } from "swiper";
import styles from "./Banner.module.scss";
import { usePageContext } from "@providers/PageProvider";
import { homePage } from "@utils/helper";

const View = () => {
  const { pageData } = usePageContext();
  const bannerData = homePage.formatBanner(pageData);
  const banners = _.get(bannerData, "slides");
  const [activeIndex, $activeIndex] = React.useState(0);
  useSwiper(".home-swiper", {
    speed: 1000,
    centeredSlides: true,
    allowTouchMove: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    modules: [Autoplay],
    on: {
      slideChange: (swiper) => {
        if (swiper) {
          $activeIndex(swiper.realIndex);
          swiper.pagination.render();
          swiper.pagination.update();
        }
      },
    },
  });
  // console.log({ banners });
  return (
    <section data-scroll-section className={cx(styles.swiper, styles.desktop)}>
      <div className={cx("swiper home-swiper")}>
        <div className="swiper-wrapper">
          {_.map(banners, (item, index: number) => {
            return (
              <SlideItem
                activeIndex={activeIndex}
                name={`slide_home_${index}`}
                className={cx("swiper-slide", styles.slideItem)}
                key={`slide_${""}_${index}`}
                {...item}
              />
            );
          })}
        </div>
        <div className="swiper-fraction">{`0${activeIndex + 1} / 0${
          banners.length
        }`}</div>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};
export default View;
