import React from "react";
import Intro from "@components/Intro";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Banner from "@components/Home/Banner";
import MobileBanner from "@components/Home/MobileBanner";
import About from "@components/Home/About";
import OurJourney from "@components/Home/OurJourney";
import OurHotel from "@components/Home/OurHotel";
import OurStory from "@components/Home/OurStory";
import StayInpired from "@components/Home/StayInspired";
import ClientWrapper from "@containers/ClientWrapper";
const View = () => {
  return (
    <div className="homePage" data-scroll-container>
      <Intro />
      <Header />
      <Banner />
      <MobileBanner />
      <About />
      <ClientWrapper>
        <>
          <OurJourney />
          <OurHotel />
          <OurStory />
          <StayInpired />
        </>
      </ClientWrapper>
      <Footer hideSocialIcons />
    </div>
  );
};

export default View;
