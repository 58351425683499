import { useTranslationContext } from "@providers/TranslationProvider";
import styles from "./About.module.scss";
import cx from "classnames";
import Image from "@components/Image";
import Button from "@components/Button";
import Link from "@components/Link";
import { useRouteContext } from "@providers/RouteProvider";
import { usePageContext } from "@providers/PageProvider";
import { homePage } from "@utils/helper";
import _ from "lodash";

const View = () => {
  const { Trans } = useTranslationContext();
  const { router } = useRouteContext();
  const { pageData } = usePageContext();
  const aboutUs = homePage.formatAboutUs(pageData);
  return (
    <section data-scroll-section className={cx("inner-large", styles.section)}>
      <div className={cx(styles.headBox)}>
        <div className={cx("flex-1 ama-title", styles.title)}>
          {_.get(aboutUs, "title")}
        </div>
        <div className="ama-content max-w-[696px]">
          <Trans
            defaults={_.get(aboutUs, "description")}
            components={[<span key="text-yellow" className="text-yellow-1" />]}
          />
        </div>
      </div>
      <div className={styles.imageBox}>
        <Image
          {..._.get(aboutUs, "image")}
          speed="-1.5"
          wrapperProps={{ className: styles.wrapperImage }}
          className={styles.image}
          layout="fill"
          objectFit="contain"
        />
      </div>
      <div className={cx("ama-content", styles.content)}>
        <Trans
          defaults={_.get(aboutUs, "content")}
          components={[<br key="break-line" />]}
        />
      </div>
      <div className={cx("mx-auto", styles.button)}>
        <Link href={_.get(aboutUs, "button.href") || "#"}>
          <Button
            className="block mx-auto"
            onClick={() => router.push("about-us")}
            label={_.get(aboutUs, "button.label")}
          />
        </Link>
      </div>
    </section>
  );
};
export default View;
