import styles from "./OurHotel.module.scss";
import { useTranslationContext } from "@providers/TranslationProvider";
import cx from "classnames";
import Link from "@components/Link";
import Button from "@components/Button";
import Hotel from "./Hotel";
import _ from "lodash";
import { usePageContext } from "@providers/PageProvider";
import { homePage } from "@utils/helper";

const View = () => {
  const { Trans } = useTranslationContext();
  const { pageData } = usePageContext();
  const ourHotels = homePage.formatOurHotels(pageData);
  return (
    <section data-scroll-section className={cx("", styles.section)}>
      <div className="divider"></div>
      <div className={cx("inner-large", styles.sectionTitle)}>
        <h2 className="ama-section-title">{_.get(ourHotels, "title")}</h2>
      </div>
      {_.map(_.get(ourHotels, "hotels", []), (item, index) => (
        <Hotel
          key={`hotel_${index}`}
          title={
            <>
              <div className={styles.desktop}>
                <Trans
                  defaults={_.get(item, "title")}
                  components={[<br key={"0"} />]}
                />
              </div>
              <div className={styles.mobile}>
                <Trans
                  defaults={_.get(item, "title")}
                  components={[<span key={"0"} />]}
                />
              </div>
            </>
          }
          description={
            <Trans
              defaults={_.get(item, "description")}
              components={[<br key={"0"} />]}
            />
          }
          button={
            <Link href={_.get(item, "button.href")}>
              <Button
                className="uppercase"
                label={_.get(item, "button.label")}
                // onClick={() => router.push("amanaki-thao-dien")}
              />
            </Link>
          }
          imageProps={_.get(item, "image")}
        />
      ))}
    </section>
  );
};
export default View;
