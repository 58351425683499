import { useTranslationContext } from "@providers/TranslationProvider";
import Image from "@components/Image";
import Link from "@components/Link";
import Button from "@components/Button";
import cx from "classnames";
import { useRouteContext } from "@providers/RouteProvider";
import _ from "lodash";
const View = ({
  topic,
  date,
  title,
  description,
  image,
  src,
  slug,
  className,
}: any) => {
  const { i18n } = useTranslationContext();
  const { router } = useRouteContext();

  return (
    <Link
      href={router.toUrl("storiesArticle", { slug })}
      className={cx("ama-post vertical", className)}
    >
      <div className="head">
        <div className="label">{topic}</div>
        <div className="date">{date}</div>
      </div>
      <Image
        wrapperProps={{ className: "" }}
        className="img fit"
        src={src ?? _.get(image, "src", "")}
        alt={title ?? _.get(image, "alt", "")}
        layout="fill"
        objectFit="cover"
        loading="eager"
      />
      <div className="title">{title}</div>
      <div className="description text-ellipsis-4">{description}</div>
      <Button
        type="icon"
        color="white"
        className={cx("uppercase button")}
        label={i18n.t("Button.moreDetail")}
        suffix="shape-arrow"
      />
    </Link>
  );
};
export default View;
