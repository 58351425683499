import React from "react";
import { useLocomotiveContext } from "@providers/LocomotiveProvider";
import Image from "@components/Image";
import styles from "./Intro.module.scss";
import styled from "styled-components";
import cx from "classnames";
import _ from "lodash";
// import ClientWrapper from "@containers/ClientWrapper";
const View = () => {
  const { scroll } = useLocomotiveContext();
  const ref: any = React.useRef({});
  React.useEffect(() => {
    if (scroll) {
      scroll.stop();
    }
    _.debounce(() => {
      if (ref.current) {
        ref.current.remove();
      }
      if (scroll) {
        scroll.start();
      }
    }, 3000)();
  }, [scroll]);
  return (
    // <ClientWrapper>
    <div
      ref={ref}
      data-scroll-section
      className={cx("fixed amin-intro", styles.section)}
    >
      <AnimLogo>
        <Image
          wrapperProps={{ className: styles.logo }}
          scale={1}
          speed={0}
          src={"/intro/logo.png"}
          alt={""}
          layout="fill"
          objectFit="contain"
        />
      </AnimLogo>
      <AminBg>
        <Image
          wrapperProps={{ className: styles.bg }}
          scale={1.1}
          speed={0}
          src={"/intro/bg.jpg"}
          alt={""}
          layout="fill"
        />
      </AminBg>
    </div>
    // </ClientWrapper>
  );
};
const AnimLogo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  .ama-img {
    transition-delay: 1.2s;
  }
`;
const AminBg = styled.div``;
export default View;
