import Image from "@components/Image";
import Video from "@components/Video";
import styles from "./Banner.module.scss";
import cx from "classnames";
import renderPropsComposer from "@utils/renderPropsComposer";
import _ from "lodash";
const View = ({ name, src, alt, mime, className }: any) => {
  return (
    <div className={cx("slide-item animated", className)}>
      <div className="absolute z-10 w-full h-full bg-black/30"></div>
      {renderPropsComposer(
        {
          matcher: ({ mime }: any) => _.includes(mime, "image"),
          render: () => (
            <Image
              loading="eager"
              src={src}
              alt={alt}
              speed="-1.5"
              wrapperProps={{ className: styles.wrapperImage }}
              className={styles.image}
              layout="fill"
              objectFit="cover"
              sizes="100vw"
            />
          ),
        },
        {
          matcher: ({ mime }: any) => _.includes(mime, "video"),
          render: () => (
            <div className={styles.wrapperVideo}>
              <Video
                name={name}
                className={styles.video}
                src={src}
                playsInline={true}
                loop="loop"
                autoPlay="autoplay"
                muted={"muted"}
              />
            </div>
          ),
        }
      )({
        mime: mime,
      })}
    </div>
  );
};
export default View;
// Changing the way you think about furniture design.
