import Image from "@components/Image";
// import { useTranslationContext } from "@providers/TranslationProvider";
import styles from "./OurHotel.module.scss";
import cx from "classnames";
// import Button from "@components/Button";

const View = ({ title, description, button, imageProps }: any) => {
  //   const { i18n } = useTranslationContext();
  return (
    <div className={cx("inner-large", styles.box)}>
      <div className={cx("flex justify-between", styles.content)}>
        {!!title && (
          <div className={cx(styles.title)}>
            <div className="flex-1 ama-title">{title}</div>
          </div>
        )}
        {!!description && (
          <div className={cx(styles.description)}>
            <div className="ama-content">{description}</div>
          </div>
        )}
        {!!button && <div className={styles.button}>{button}</div>}
        {/* <div className={cx("mx-auto", styles.button)}>
        <Button className="block mx-auto" label="Read more" />
      </div> */}
      </div>
      <div className={styles.imageBox}>
        <Image
          speed="-1.5"
          wrapperProps={{ className: styles.wrapperImage }}
          className={styles.image}
          src={"/home/about.jpg"}
          alt={"about amanaki"}
          layout="fill"
          objectFit="contain"
          {...imageProps}
        />
      </div>
    </div>
  );
};
export default View;
